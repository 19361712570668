<template>
  <div class="pre-screening-response">
    <CRow
      v-for="(transaction,index) in preScreeningTransactions"
      :key="transaction.id"
      class="mb-2"
    >
      <CCol md="12" v-if="transaction.job_question">
        <CRow>
          <label class="col-lg-12 col-md-12"><span class="mr-1">Q{{(index+1)}}.</span>{{
            transaction.job_question.question
          }}</label>
          <CCol md="12">
            <Select
              v-if="[6, 7].includes(transaction.job_question.question_type_id)"
              :name="`options_response_${transaction.id}`"
              :value="transaction.options_response"
              :clearable="false"
              :multiple="transaction.job_question.question_type_id === 7"
              :disabled="true"
            />
            <RadioButton
              v-if="transaction.job_question.question_type_id === 1"
              :name="`yes_no_response_${transaction.id}`"
              :value="transaction.yes_no_response"
              :options="options && options['boolean'] ? options['boolean'] : []"
              :disabled="true"
            />
            <TextareaInput
              v-if="transaction.job_question.question_type_id === 2"
              :name="`text_area_response_${transaction.id}`"
              :value="transaction.text_area_response"
              :disabled="true"
            />
            <TextInput
              v-if="transaction.job_question.question_type_id === 3"
              :name="`numeric_response_${transaction.id}`"
              type="number"
              :value="transaction.numeric_response"
              :disabled="true"
            />
            <Rating
              v-if="transaction.job_question.question_type_id === 5"
              :name="`rating_count_${transaction.id}`"
              :isNumberRating="true"
              :maxRating="transaction.job_question.max_rating_count"
              :value="transaction.rating_count"
              :disabled="true"
            />
            <DatePicker
              v-if="transaction.job_question.question_type_id === 4"
              :name="`date_response_${transaction.id}`"
              format="DD-MM-YYYY"
              :value="transaction.date_response"
              :disabled="true"
            />
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Select from "@/components/reusable/Fields/Select";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import Rating from "@/components/reusable/Fields/Rating";
export default {
  name: "PreScreeningResponse",
  props: {
    preScreeningTransactions: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
    TextareaInput,
    DatePicker,
    RadioButton,
    Rating,
  },
  computed: {
    options() {
      return {
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
      };
    },
  },
};
</script>