<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">{{ modalTitle }}</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer-wrapper>
        <div class="border-top d-flex py-3 mx-3">
          <div class="flex-grow-1 d-flex">
            <a
              v-if="showDownload"
              @click="generatePDF"
              v-c-tooltip="'Click to Download File'"
              ><i class="fas fa-download ml-1 text-primary"></i
            ></a>
          </div>

          <div class="d-flex">
            <CButton
              type="button"
              variant="outline"
              color="primary"
              class="px-4"
              @click="modalCallBack(false)"
              >Close</CButton
            >
          </div>
        </div>
      </template>
      <div>
        <InterviewFeedBack :feedBackInfo="feedBackInfo" ref="interviewFeedBack" :showPrint="false"/>
      </div>
    </CModal>
  </div>
</template>
<script>
import InterviewFeedBack from "@/components/reusable/InterviewFeedBack.vue";
import m from "moment";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: "Interview Evaluation Form",
    },
    feedBackInfo: {
      type: Object,
      default: null,
    },
    showDownload: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    InterviewFeedBack,
  },
  data() {
    return {
      disabled_style: "background-color: #f8f8f8 !important;",
    };
  },
  methods: {
    modalCallBack(action) {
      this.$emit("modalCallBack", action);
    },
    generatePDF(){
      this.$refs.interviewFeedBack.generateReport()
    }
  },
};
</script>