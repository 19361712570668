<template>
  <div class="shortlist-comment">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Shortlist Comment</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit"
          >Submit</CButton
        >
      </template>
      <div>
        <ValidationObserver ref="shortlist" v-slot="{ handleSubmit }">
          <form id="shortlist" @submit.prevent="handleSubmit(onSubmit)">
            <CRow class="mb-2">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Job</label>
                  <CCol md="12" lg="12">
                    <Select
                      name="job_id"
                      :value="uploadData.job_id"
                      :options="[]"
                      :clearable="false"
                      :disabled="true"
                      :multiple="false"
                      :taggable="false"
                    />
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Action</label>
                  <div class="col-lg-12">
                    <ValidationProvider
                      ref="action"
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <Select
                        name="action_id"
                        :value="uploadData.action_id"
                        @input="handleChangeSelect"
                        :options="
                          options && options['actionOptions']
                            ? options['actionOptions']
                            : []
                        "
                        :error="errors[0]"
                        :clearable="false"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol md="6">
                <CRow>
                  <label class="col-lg-12 col-md-12">Comments</label>
                  <CCol md="12" lg="12">
                    <TextAreaInput
                      name="comments"
                      :value="uploadData.comments"
                      label="comments"
                      @input="handleInput"
                    />
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Select from "@/components/reusable/Fields/Select.vue";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    jobDetail: {
      type: Object,
      default: {},
    },
    candidateDetail: {
      type: Object,
      default: {},
    },
  },
  components: {
    Select,
    TextAreaInput,
  },
  data() {
    return {
      uploadData: {},
      actions: [
        {
          label: "Hospital Shortlist",
          code: { update_status: 67, update_sub_status: 41 },
        },
        {
          label: "Shortlist",
          code: { update_status: 68, update_sub_status: 41 },
        },
      ],
      UKMainActions: [
        {
          label: "Shortlist",
          code: { update_status: 68, update_sub_status: 41 },
        },
      ],
      jobSupplierActions: [
        {
          label: "Hospital Shortlist",
          code: { update_status: 67, update_sub_status: 41 },
        },
      ],
      candidateSupplierActions: [
        {
          label: "Agency Matched",
          code: { update_status: 75, update_sub_status: 41 },
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "isUKMainAgency",
      "isJobSupplierFromAccessToken",
      "isCandidateSupplierFromAccessToken",
    ]),
    options() {
      return {
        actionOptions:
          !this.isUKMainAgency &&
          !this.isCandidateSupplierFromAccessToken &&
          !this.isJobSupplierFromAccessToken
            ? this.actions
            : this.isUKMainAgency
            ? this.UKMainActions
            : this.isCandidateSupplierFromAccessToken
            ? this.candidateSupplierActions
            : this.isJobSupplierFromAccessToken
            ? this.jobSupplierActions
            : [],
      };
    },
  },
  methods: {
    ...mapActions(["updateCandidateJobStatus", "showToast"]),
    handleInput(name, value) {
      Vue.set(this.uploadData, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.uploadData, name, value);
    },
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    async onSubmit() {
      const isValid = await this.$refs.shortlist.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      const finalPayload = {
        payloadData: {
          job_id: this.uploadData?.job_id?.code,
          candidate_uid: this.uploadData?.candidate_uid,
          payload: { comments: this.uploadData?.comments },
        },
        updateStatusSubStatus: this.uploadData?.action_id?.code,
      };
      this.updateCandidateJobStatus(finalPayload).then((res) => {
        this.modalCallBack(false);
      });
    },
  },
  mounted() {
    const job_id = this.jobDetail
      ? {
          code: this.jobDetail?.job_id,
          label: this.jobDetail?.job_title,
        }
      : null;
    Vue.set(this.uploadData, "job_id", job_id);
    this.uploadData.candidate_uid = this.candidateDetail?.candidate_uid;
  },
};
</script>