<template>
  <div class="shortlist-modal">
    <div v-if="!actionModal.isShowPopup">
      <CModal
        color="primary"
        :show.sync="isShowPopup"
        :close-on-backdrop="false"
        size="lg"
      >
        <template #header>
          <h6 class="modal-title">
            {{ candidateDetail.first_name }} {{ candidateDetail.surname }} -
            Shortlist
          </h6>
          <CButtonClose @click="modalCallBack(false)" class="text-black" />
        </template>
        <template #footer>
          <CButton
            type="button"
            variant="outline"
            color="primary"
            class="px-4"
            @click="modalCallBack(false)"
            >Close</CButton
          >
        </template>
        <LoadingOverlay v-if="isFetching" />
        <div v-else>
          <CDataTable
            :striped="true"
            :bordered="true"
            :small="true"
            :items="jobList"
            :fields="fields"
            pagination
            :items-per-page="10"
            class="p-2"
          >
            <template #action="{ item }">
              <td>
                <CButton
                  v-if="!(item.status_id && item.status_id != 42)"
                  color="primary"
                  @click="openShortListAction(item)"
                >
                  Shortlist
                </CButton>
              </td>
            </template>
          </CDataTable>
        </div>
      </CModal>
    </div>
    <ShortListCommentModal
      v-if="actionModal.isShowPopup"
      :isShowPopup="actionModal.isShowPopup"
      :jobDetail="actionModal.jobDetail"
      :candidateDetail="actionModal.candidateDetail"
      @modalCallBack="actionModalCallBack"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ShortListCommentModal from "@/components/reusable/ShortList/ShortListCommentModal";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    candidateDetail: {
      type: Object,
      default: {},
    },
  },
  components: {
    ShortListCommentModal,
  },
  data() {
    return {
      isFetching: false,
      fields: [
        { key: "job_title", label: "Job", _style: "width:40%" },
        { key: "status", label: "Status", _style: "width:40%" },
        { key: "action", label: "Action", _style: "width:20%" },
      ],
      actionModal: {
        isShowPopup: false,
        jobDetail: null,
        candidateDetail: null,
      },
    };
  },
  computed: {
    ...mapGetters(["jobListForSpecificCandidate"]),
    jobList() {
      return this.jobListForSpecificCandidate?.map((v) => ({
        job_title: `${v?.job_title} | ${v?.job_display_uid}`,
        job_id: v?.job_id,
        status: v?.status_id
          ? v?.status_id === 42
            ? "Matched"
            : `Matched (Already Shortlisted)`
          : "--",
        status_id: v?.status_id,
      }));
    },
  },
  methods: {
    ...mapActions(["getJobListForCandidate"]),
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
    openShortListAction(job) {
      this.actionModal.jobDetail = job;
      this.actionModal.candidateDetail = this.candidateDetail;
      this.actionModal.isShowPopup = true;
    },
    actionModalCallBack(action, data) {
      this.actionModal.isShowPopup = false;
      this.initAction();
    },
    initAction() {
      const { candidate_uid, customer_uid } = this.candidateDetail;
      this.isFetching = true;
      this.getJobListForCandidate({
        candidate_uid,
        customer_uid,
      }).then((res) => {
        this.isFetching = false;
      });
    },
  },
  mounted() {
    this.initAction();
  },
};
</script>