<template>
  <div>
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">{{modalTitle}}</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          v-if="!isPreview"
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          >Save & Apply</CButton
        >
      </template>
      <div class="preview">
        <ValidationObserver ref="preScreeningForm" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(onSubmit)"
            name="preScreeningForm"
          >
            <CRow
              v-for="(question, index) in questions"
              :key="index"
              class="mb-2"
            >
              <CCol md="12" v-if="question.question">
                <CRow>
                  <label
                    class="col-lg-12 col-md-12"
                    :class="{ required: question.is_mandatory }"
                    ><span class="mr-2 text-muted">Q {{ index + 1 }}.</span
                    ><span class="font-weight-bold">{{
                      question.question
                    }}</span></label
                  >
                  <CCol md="12" v-if="question.question_type_id">
                    <ValidationProvider
                      :rules="{ required: question.is_mandatory }"
                      v-slot="{ errors }"
                    >
                      <Select
                        v-if="[6, 7].includes(question.question_type_id.code)"
                        :name="`options_response_${index}`"
                        :value="question.answer.options_response"
                        :options="question.options || []"
                        @change="handleChangeSelect"
                        :multiple="question.question_type_id.code === 7"
                        :error="errors[0]"
                      />
                      <RadioButton
                        v-if="question.question_type_id.code === 1"
                        :name="`yes_no_response_${index}`"
                        :value="question.answer.yes_no_response"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleRadio"
                        :error="errors[0]"
                      />
                      <TextareaInput
                        v-if="question.question_type_id.code === 2"
                        :name="`text_area_response_${index}`"
                        :value="question.answer.text_area_response"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                      <TextInput
                        v-if="question.question_type_id.code === 3"
                        :name="`numeric_response_${index}`"
                        type="number"
                        :value="question.answer.numeric_response"
                        @input="handleInput"
                        :error="errors[0]"
                      />
                      <Rating
                        v-if="question.question_type_id.code === 5"
                        :name="`rating_count_${index}`"
                        :isNumberRating="true"
                        :maxRating="question.max_rating_count"
                        :value="question.answer.rating_count"
                        @change="handleChangeRating"
                        :error="errors[0]"
                      />
                      <DatePicker
                        v-if="question.question_type_id.code === 4"
                        :name="`date_response_${index}`"
                        format="DD-MM-YYYY"
                        :value="question.answer.date_response"
                        @input="handleDatePickerChange"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import PhoneInput from "@/components/reusable/Fields/PhoneInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import DateInput from "@/components/reusable/Fields/DateInput";
import TimePicker from "@/components/reusable/Fields/TimePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import CheckBox from "@/components/reusable/Fields/CheckBox";
import Rating from "@/components/reusable/Fields/Rating";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";

import Vue from "vue";
import _ from "lodash";
export default {
  props: {
    preScreeningQuestions: {
      type: Array,
      default: () => [],
    },
    jobInfo: {
      type: Object,
      default: {},
    },
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
    modalTitle:{
      type:String,
      default:"Pre Screening Questions"
    }
  },
  components: {
    Select,
    TextInput,
    PhoneInput,
    TextareaInput,
    DatePicker,
    DateInput,
    RadioButton,
    CheckBox,
    TimePicker,
    PhoneInputwithCountry,
    Rating,
  },
  data() {
    return {
      questions: [],
    };
  },
  computed: {
    options() {
      return {
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
      };
    },
  },
  methods: {
    ...mapActions(["showToast"]),
    handleChangeSelect(_name, value) {
      let val = value;
      if (!Array.isArray(value)) val = [value];
      const { name, index } = this.decodeName(_name);
      Vue.set(this.questions[index].answer, name, val);
    },
    handleRadio(_name, value) {
      const { name, index } = this.decodeName(_name);
      let val = null;
      if (value === null) {
        val = null;
      } else if (value.id === false || value.code === false) {
        val = false;
      } else if (value.id === null || value.code === null) {
        val = null;
      } else {
        val = value.id || value.code || value;
      }

      Vue.set(this.questions[index].answer, name, val);
    },
    handleInput(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.questions[index].answer, name, value);
    },
    handleChangeRating(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.questions[index].answer, name, value);
    },
    handleDatePickerChange(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.questions[index].answer, name, value);
    },
    decodeName(data) {
      if (data) {
        const split_index = data.lastIndexOf("_");
        const name = data.substr(0, split_index);
        const index = data.substr(split_index + 1) || null;
        return { name, index };
      }
      return data;
    },
    async onSubmit() {
      const isValid = await this.$refs.preScreeningForm.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      this.modalCallBack(true, this.questions);
    },
    modalCallBack(action, data) {
      this.$emit("modalCallBack", action, data);
    },
  },
  mounted() {
    this.questions = this.preScreeningQuestions.map((v) => {
      return {
        question_type_id: {
          code: v?.question_type?.question_type_id,
          label: _.startCase(v?.question_type?.question_type_name),
        },
        question: v?.question,
        is_mandatory: v?.is_mandatory,
        options: v?.options,
        max_rating_count: v?.max_rating_count,
        question_id: v?.question_id,
        answer: {},
      };
    });
  },
};
</script>